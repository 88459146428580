import { loadScript, removePaymentChannelTile } from '../tools';
import { pulseOnLoad } from '../../input_tools';

// An external script is used to generate fingerprintId required by ZEN.
const SEON_SCRIPT_URL = 'https://cdn.deviceinf.com/js/v5/agent.js';
// An external script is used handle ZEN credit card checkout widget (popup).
const ZEN_CHECKOUT_SCRIPT_URL = 'https://cdn-secure.zen.com/zen-checkout.1.0.0.js';
const ZEN_SANDBOX_CHECKOUT_SCRIPT_URL = 'https://cdn-secure.zen-test.com/zen-checkout.1.0.0.js';
const ZEN_CARD_CHANNEL_ID_ATTR = 'data-zen-card-channel-id';
const ZEN_APPLE_PAY_CHANNEL_ID_ATTR = 'data-zen-apple-pay-channel-id';
const ZEN_APPLE_PAY_MERCHANT_ID_ATTR = 'data-zen-apple-pay-merchant-id';
const ZEN_AUTHORIZE_APPLE_PAY_URL_ATTR = 'data-zen-authorize-apple-pay-url';

export const widgetConfigParams = {
  boldFont: 'Roboto',
  regularFont: 'Roboto',
  overlayBackgroundsColor: 'rgba(0,0,0,0.5)',
  containerBackgroundColor: '#F9F9F9',
  containerBorderRadius: '10px',
  containerBorderColor: '1px solid #D3D4DC',
  containerBoxShadow: '0px 4px 34px 0px rgba(0, 0, 0, 0.25)',
  topBarBackgroundColor: '#FFFFFF',
  topBarFontColor: '#222222',
  topBarCloseIconColor: '#222222',
  inputBackgroundColor: '#FFFFFF',
  inputPrefixIconColor: '#e6e8eb',
  inputErrorColor: '#fb635f',
  inputFontColor: '#222222',
  inputPlaceholderFontColor: '#a8a8a8',
  inputBorderRadius: '5px',
  inputBorderColor: '1px solid #d3d4dc',
  inputBoxShadow: 'unset',
  buttonBackgroundColor: '#fb635f',
  buttonFontColor: '#FFFFFF',
  buttonBorderRadius: '5px',
  buttonBorderColor: 'unset',
  buttonBoxShadow: 'unset',
  fontAndIconColor: '#999999',
  statusSuccessIconColor: '#3fb77b',
  statusFailureIconColor: '#fb635f',
  statusPendingIconColor: '#3fb77b',
  statusTextColor: '#222222',
};

export function initZenPayments($form, sandbox = false) {
  /*
   * ZEN is available for credit card / Apple Pay payment methods.
   * Current implementation uses a popup widget provided by ZEN for credit card payments.
   * TODO: check whether fingerprint and browser details are required for Apple Pay.
   * fingerprintId and browserDetails are still set, even though they are not required by the checkout api.
   * Fingerprints are used for fraud prevention by Pomagam backend.
   *
   */
  const credit_cards_enabled = $form.hasClass('js-allow-zen-cc');
  const apple_pay_enabled = $form.hasClass('js-allow-zen-apple-pay');
  if (credit_cards_enabled || apple_pay_enabled) {
    loadScript(
      SEON_SCRIPT_URL,
      function() {
        initZenFields(apple_pay_enabled);
        if (credit_cards_enabled) {
          initZenWidgetEvents();
        }
      },
      onFailZen,
    );

    if ($form.is('.js-zen-sandbox-mode')) {
      loadScript(ZEN_SANDBOX_CHECKOUT_SCRIPT_URL, null, onFailZen);
    } else {
      loadScript(ZEN_CHECKOUT_SCRIPT_URL, null, onFailZen);
    }
  }
}

let scripts_failed_to_load = false;

function onFailZen() {
  // Prevent multiple calls
  if (scripts_failed_to_load) {
    return;
  }
  // Remove zen payment channel tile
  const $form = $('#payment-form');
  const cardChannelId = $form.attr(ZEN_CARD_CHANNEL_ID_ATTR);
  const applePayChannelId = $form.attr(ZEN_APPLE_PAY_CHANNEL_ID_ATTR);

  removePaymentChannelTile(cardChannelId);
  removePaymentChannelTile(applePayChannelId);
  scripts_failed_to_load = true;
}

function initZenWidgetEvents() {
  if (typeof seon === 'undefined') {
    onFailZen();
    return;
  }

  const $form = $('#payment-form');
  const $submitBtn = $form.find('#submit-btn');
  const loader_class = 'pulse-on-load';
  const cardChannelId = $form.attr(ZEN_CARD_CHANNEL_ID_ATTR);

  $form.find('.bank-option > label').on('click', function(e) {
    // Due to some internal shenanigans,
    // `.bank-option` click event triggers twice
    // with different targets (input and image).
    // This is a workaround to prevent double triggering of the event.
    // Additionally, remember to DO NOT directly bind on .bank-option element
    // because it's bigger than its visual representation.
    if (e.target.nodeName === 'INPUT') {
      return;
    }

    var $this = $(this);

    const paymentChannel = $this.find('input').attr('value');
    if (paymentChannel === cardChannelId) {
      $submitBtn.addClass(loader_class);
      pulseOnLoad($form);
    } else {
      // Handles the case when user chooses ZEN and switches to another channel
      // In such case, all stuff added by pulseOnLoad function needs to be removed
      if ($submitBtn.hasClass(loader_class)) {
        $submitBtn.removeClass(loader_class);
        $submitBtn.off('click.pulseOnLoad');
      }
      if ($submitBtn.parent().is('.load-button-wrapper')) {
        $submitBtn.unwrap('.load-button-wrapper');
      }
      if ($submitBtn.siblings('em.signal').length) {
        $submitBtn.siblings('em.signal').remove();
      }
    }
  });
}

function initZenFields(init_apple_pay) {
  // Double check, script may have loaded, but failed internally and not exposed seon.
  if (typeof seon === 'undefined') {
    onFailZen();
    return;
  }

  const form = document.getElementById('payment-form');

  if (init_apple_pay) {
    initApplePay();
  } else {
    removePaymentChannelTile(form.getAttribute(ZEN_APPLE_PAY_CHANNEL_ID_ATTR));
  }

  seon.config({
    host: 'deviceinf.com',
    session_id: 'zen_' + form.getAttribute('data-session-time'),
    audio_fingerprint: true,
    canvas_fingerprint: true,
    webgl_fingerprint: true,
    onSuccess: function(message) {},
    onError: function(message) {},
  });

  seon.getBase64Session(function(data) {
    if (data) {
      document.getElementById('id_fingerprint').value = data;
    }
  });

  const browserDetails = {
    acceptHeader: decodeURIComponent(form.getAttribute('data-accept-header')),
    colorDepth: window.screen.colorDepth,
    javaEnabled: window.navigator.javaEnabled(),
    lang: decodeURIComponent(form.getAttribute('data-language')),
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timezone: new Date().getTimezoneOffset(),
    windowSize: '05',
    userAgent: decodeURIComponent(form.getAttribute('data-user-agent')),
  };
  document.getElementById('id_browser_details').value = JSON.stringify(
    browserDetails,
  );
}

function initApplePay() {
  const APPLE_PAY_VERSION = 12;
  const $form = $('#payment-form');
  const applePayChannelId = $form.attr(ZEN_APPLE_PAY_CHANNEL_ID_ATTR);
  const zenApplePayMerchantId = $form.attr(ZEN_APPLE_PAY_MERCHANT_ID_ATTR);

  if (!window.PaymentRequest) {
    // console.log("Remove Apple Pay tile from payment form");
    removePaymentChannelTile(applePayChannelId);
    return;
  }

  /*
    This configuration object is compatible with
    https://developer.apple.com/documentation/apple_pay_on_the_web/payment_request_api/setting_up_the_payment_request_api_to_accept_apple_pay
  */
  window.ApplePaySupportedInstruments = [
    {
      supportedMethods: 'https://apple.com/apple-pay',
      data: {
        merchantIdentifier: zenApplePayMerchantId,
        supportedNetworks: ['visa', 'mastercard'],
        merchantCapabilities: ['supports3DS'],
        countryCode: 'PL',
        currencyCode: 'pln',
        version: APPLE_PAY_VERSION,
      },
    },
  ];

  const paymentRequest = new PaymentRequest(window.ApplePaySupportedInstruments, {
      total: {
        label: 'Total',
        amount: {
          currency: 'PLN',
          value: '1.00',
        },
      },
    },
  );

  paymentRequest.canMakePayment().then((result) => {
    if (!result) {
      // console.log("Remove Apple Pay tile from payment form");
      removePaymentChannelTile(applePayChannelId);
    }
  });
}

export function createApplePayPaymentRequest(value) {
  return new Promise((resolve, reject) => {
    try {
      const zenAuthorizeApplePayUrl = $('#payment-form').attr(ZEN_AUTHORIZE_APPLE_PAY_URL_ATTR);
      const paymentRequest = new PaymentRequest(window.ApplePaySupportedInstruments, {
          total: {
            label: 'Wpłata na Pomagam.pl',
            amount: {
              value: value,
              currency: 'PLN',
            },
          },
        },
      );

      /*
        It's just for Apple devices, more in below link
        https://developer.mozilla.org/en-US/docs/Web/API/PaymentRequest/merchantvalidation_event
      */
      paymentRequest.onmerchantvalidation = event => {
        $.ajax({ method: 'post', url: zenAuthorizeApplePayUrl })
          .done(data => {
            event.complete(data);
          })
          .catch(error => {
            event.complete(error);
          });
      };

      paymentRequest.show().then(paymentResponse => {
        resolve(paymentResponse);
      });
    } catch (e) {
      reject(e);
    }
  });
}
